import React from 'react'
import Card from '@mui/material/Card'
import { Box, CardActions, CardMedia } from '@mui/material'
import CardContent from '@mui/material/CardContent'
import { useTranslation } from 'react-i18next'
import HexadButton from '../../../../Components/HexadButton/HexadButton'

export function Service ({ serviceElement }) {
    const { t } = useTranslation()

    return <Card
        variant="outlined"
        spacing={4}
        sx={{
            flexShrink: 0,
            borderRadius: '8px',
            border: '1px solid #FF5F0E',
            display: 'flex',
            flexDirection: 'column',
            width: { xs: 320, sm: 324 },
            margin: 1,
            justifyContent: 'space-between',
            alignSelf: 'stretch'
        }}
    >
        <Box>
            <CardMedia
                component="img"
                height={172}
                image={serviceElement.image}
                alt={serviceElement.name}
            />
            <CardContent>
                <h5 style={{ fontSize: '16px',marginBottom: '16px', textAlign: 'left' }}>
                    {serviceElement.name}
                </h5>

                <p style={{
                    textAlign: 'left',
                    fontSize: '12px',
                    scrollBehavior: 'auto',
                }}>
                    {serviceElement.description}
                </p>
            </CardContent>
        </Box>
        <CardActions disableSpacing style={{
            alignContent: 'end',
            justifyContent: 'flex-end',
            width: '95%',
        }}>
            <HexadButton text={t('GetInTouch')}
                         link={'/get-in-touch'} small
                         inverted={false} color="success"
                         variant="text"/>
        </CardActions>
    </Card>
}