import React from 'react';
import './hexadButton.scss';
import {Link} from "react-router-dom";

const HexadButton = ({text, inverted, spaced,link = '/', external, onClick = () => {}, small, className, id}) => {

    return (
        <Link
            to={link}
            target={!!external ? '_blank' : undefined}
            id={`${!!id?id : 'hexad-button'}`}
            data-testid={'hexad-button'}
            className={`${!!className?className : 'hexad-button'} ${!!inverted && 'inverted'} ${!!spaced && 'spaced'} ${!!small && 'small'}`}
            onClick={() => onClick()}>
            {text}
        </Link>
    )
}

export default HexadButton;