import React, { useState } from 'react'
import './ourServicesSection.scss'
import { Box, Stack, styled } from '@mui/material'
import HexadButton from '../../../../Components/HexadButton/HexadButton'
import { Service } from './Service'

export function ServicesGroup ({ serviceGroups }) {
    const [group, setGroup] = useState(0);

    styled('h4')(({ theme }) => ({
        marginBottom: theme.spacing('medium'),
        fontSize: '1.5rem',
        color: theme.palette.text.primary,
    }))
    let imageNames = []
    const directory = require.context('/src/styles/images/services/', false,
        /\.(png|jpe?g|svg|webp)$/)
    directory.keys().forEach(item => imageNames.push(item.replace('./', '')))

    let images = []
    imageNames.forEach(path => {
            let indexService = parseInt(path.substring(0, 2), 10)
            images[indexService] = (require('/src/styles/images/services/' + path))
        },
    )

    return <Box sx={{ width: '100%' }} id={'ServicesGroup'}>
        <Box
            sx={{
                mb: 12,
                borderRadius: 1,
                alignContent: 'center'
            }}
        >
            <Stack
                direction="row"
                sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: 8,
                    flexWrap: 'wrap',
                    marginLeft: { xs: '2.5em', sm: '0em' },
                    marginRight: { xs: '2.5em', sm: '0em' }
                }}
                spacing={{ xs: 1, sm: 2 }}
                useFlexGap
            >
                {serviceGroups.map((serviceGroup, index) => (
                    <HexadButton
                        key={index}
                        id={'hexad-button-group-service'}
                        className={'hexad-button-group-service'}
                        text={serviceGroup.title}
                        onClick={() => setGroup(index)}
                        small
                        inverted={(index === group)} color="success"
                        variant="text"/>

                ))}
            </Stack>
            <Stack
                direction="row"
                spacing={{ xs: 1, sm: 2 }}
                sx={{
                    justifyContent: {sm: 'center', xs: 'flex-start'},
                    alignItems: 'center',
                    flexWrap: { xs: 'nowrap', sm: 'wrap' },
                    overflowX: 'auto',
                    gap: 1,
                    padding: 1,
                }}
                useFlexGap
            >
                {serviceGroups[group].services.map(
                    (serviceElement, serviceIndex) => (
                        <Service
                            key={serviceIndex}
                            serviceElement={{
                                ...serviceElement,
                                image: images[serviceElement.id],
                            }}>
                        </Service>
                    ))}
            </Stack>
        </Box>
    </Box>
}