import React, { useEffect } from 'react'
import './KitConsulting.scss'
import KitConsultingLogos
    from '../../styles/images/KC_conClaim_conBandera_Colores.png'
import Breadcrumbs from '../../Components/Breadcrumbs/Breadcrumbs'
import { useTranslation } from 'react-i18next'
import digitalTransformationImage
    from '../../styles/images/transformacion-digital.jpeg'
import CustomizedAccordion from './CustomizedAccordion'
import kitDigitalQuestions from '../KitConsulting/KitConsultingQuestions.json'
import HexadButton from '../../Components/HexadButton/HexadButton'

const KitConsulting = () => {
    const { t } = useTranslation()
    const kitDigitalQuestionsValues = kitDigitalQuestions[t('Language')]
    const imageText = t('ImageText1') + t('ImageText2');

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div id={'kit-consulting'}>
                <div className={'top-area'}/>
                <div className="kit-consulting-container hero-container">
                    <div
                        className={'kit-consulting-content responsive-container responsive-container__slimmer'}>
                        <Breadcrumbs/>
                        <h3>{t('KitConsulting')}</h3>
                        <p style={{
                            textAlign: 'left',
                            fontSize: '16px',
                            scrollBehavior: 'auto',
                        }}>
                            {t('KitConsultingDescription')}
                        </p>
                        <CustomizedAccordion
                            kitDigitalQuestions={[kitDigitalQuestionsValues[0]]}/>
                        <p style={{
                            textAlign: 'left',
                            fontSize: '34px',
                            scrollBehavior: 'auto',
                        }}>
                            {kitDigitalQuestionsValues[1].question}
                        </p>
                        <p style={{
                            textAlign: 'left',
                            fontSize: '16px',
                            scrollBehavior: 'auto',
                        }}>
                            {kitDigitalQuestionsValues[1].response}
                            <HexadButton
                                text={t('KitConsultingContactInvitationLink')}
                                link={t('KitConsultingContactInvitationUrl')}
                                small
                                inverted={true} spaced={true} color="success"
                                variant="text"/>
                        </p>
                    </div>
                    <div className={'kit-consulting-image'}>
                        <div className="headline-container">
                            <h4 className={'headline'}>{imageText}</h4>
                        </div>
                        <div className={'overlay'}/>
                        {/* eslint-disable-next-line*/}
                        <img className={'background'}
                             src={digitalTransformationImage}
                             alt="Kit Consulting image"/>
                    </div>
                </div>
                <div id={"kit-consulting-bottom"}
                     className={'responsive-container responsive-container__slimmer kit-consulting-bottom'}
                >
                    <div id={'kit-consulting-bottom-banner'}
                         className={'kit-consulting-bottom-banner responsive-container responsive-container__slimmer'}>
                        <p style={{
                            textAlign: 'center',
                            fontSize: '48px',

                        }}>
                            {t('KitConsultingServiceInvitation')}
                        </p>
                        <p style={{
                            textAlign: 'center',
                            fontSize: '20px',
                            padding: '18px 36px',

                        }}>
                            {t('KitConsultingBanner')}
                        </p>
                        <div style={{
                                display: 'flex',
                                justifyContent: 'center'}}>
                            <HexadButton
                                text={t('KitConsultingServiceInvitationLink')}
                                link={t('KitConsultingServiceInvitationUrl')}
                                small
                                inverted={false} spaced={true} color="success"
                                variant="text"/>
                        </div>
                    </div>
                </div>
                <div id={'footer-bottom-KitConsulting'}
                     className={'responsive-container responsive-container__slimmer'}>
                <img src={KitConsultingLogos} alt={'icons'}
                         style={{ width: '100%', alignContent: 'center' }}/>
                </div>
            </div>
        </>)
}

export default KitConsulting