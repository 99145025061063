import React from 'react';
import './breadcrumbs.scss';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

const Breadcrumbs = () => {
    const location = useLocation();
    const { t } = useTranslation();

    return (
        <nav id={'breadcrumbs'}>
            <Link to="/">Home</Link>
            <span className="breadcrumb-arrow">/</span>
            {location.pathname === "/career" && <p>{t('CareerButton')}</p>}
            {location.pathname === "/kit-consulting" && <p>{t('KitConsulting')}</p>}
            {location.pathname === "/get-in-touch" && <p>{t('GetInTouch')}</p>}
            {location.pathname === "/legal-notice" && <p>{t('LegalNotice')}</p>}
            {location.pathname === "/privacy-policy" && <p>{t('PrivacyPolicy')}</p>}
            {location.pathname === "/cookie-policy" && <p>{t('CookiePolicy')}</p>}
        </nav>
    );
}

export default Breadcrumbs;