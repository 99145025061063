import React, {useEffect, useState} from 'react';
import './hexadHeader.scss';
import {Link} from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import hexadWhite from '../styles/images/white-full-logo.svg';
import hexadColor from '../styles/images/color-full-logo.svg';
import HexadButton from "../Components/HexadButton/HexadButton";
import OutsideAlerter from "../Navigation/OutsideAlerter/OutsideAlerter";
import { useTranslation } from 'react-i18next';
import LanguageSelector from "./LanguageSelector/LanguageSelector";


const HexadHeader = ({fixed}) => {
    const [isOpen, setIsOpen] = useState(false);
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState();

    useEffect(() => {
        setLanguage(i18n.language);
    }, [i18n.language]);

    const handleLanguages = (event) => {
        i18n.changeLanguage(event.target.value);
        setIsOpen(false);
    }

    const getMenu = () => {
        return (
            <ul>
                <li>
                    <HashLink to="/#about-us" onClick={() => isOpen && setIsOpen(false)}>{t('AboutUsButton')}</HashLink>
                </li>
                <li>
                    <HashLink to="/#expertise" onClick={() => isOpen && setIsOpen(false)}>{t('OurExpertise')}</HashLink>
                </li>
                <li>
                    <HashLink to="/#services" onClick={() => isOpen && setIsOpen(false)}>{t('OurServices')}</HashLink>
                </li>
                <li>
                    <HashLink to="/#clients" onClick={() => isOpen && setIsOpen(false)}>{t('Clients')}</HashLink>
                </li>
                <li>
                    {isOpen ?
                        <Link to={'/career'} className={'link-button'} onClick={() => setIsOpen(false)}>{t('CareerButton')}</Link> :
                    <HexadButton text={t('CareerButton')} link={'/career'} inverted={fixed} small />}
                </li>
                <li>
                    {isOpen ?
                        <Link to={'/kit-consulting'} className={'link-button'} onClick={() => setIsOpen(false)}>{t('KitConsulting')}</Link> :
                    <HexadButton text={t('KitConsulting')} link={'/kit-consulting'} inverted={fixed} small />}
                </li>
                <li>
                    {isOpen ?
                        <Link to={'/get-in-touch'} className={'link-button'} onClick={() => setIsOpen(false)}>{t('GetInTouch')}</Link> :
                    <HexadButton text={t('GetInTouch')} link={'/get-in-touch'} inverted={fixed} small />}
                </li>
                <li>
                    {isOpen ?
                        <ul className={'language-link'} onChange={handleLanguages}>
                            {language !== 'en' &&
                                <li>
                                    <input type='radio' value='en' name='radio' id='english'/>
                                    <label htmlFor='english'>Switch to English</label>
                                </li>}
                            {language !== 'es' &&
                                <li>
                                    <input type='radio' value='es' name='radio' id='spanish'/>
                                    <label htmlFor='spanish'>Cambiar a Español</label>
                                </li>}
                        </ul>
                    : <LanguageSelector language={language} />}
                </li>
            </ul>
        )
    }

    return (
        <OutsideAlerter onOutside={() => setIsOpen(false)}>
            <header id={"hexad-header"} className={`responsive-container__slimmer ${isOpen ? 'dropdown-open' : ''}`}>
                <HashLink to={'/#intro-section'} onClick={() => isOpen && setIsOpen(false)} className={'logo-link'}>
                    <img src={fixed ? hexadColor : hexadWhite} alt={'Hexad logo'} className={'hexad-logo'}/>
                </HashLink>

                <div className={'full-menu'}>
                    {getMenu()}
                </div>

                <div className={'burger-menu'} onClick={() => setIsOpen(!isOpen)}>
                    <span className={isOpen ? 'regularXmark' : 'regularBars'} />
                </div>
            </header>
            {isOpen &&
                <div className={`burger-dropdown responsive-container__slimmer ${fixed ? '' : 'scrolling'}`}>
                    {getMenu()}
                </div>
            }
        </OutsideAlerter>
    )
}

export default HexadHeader;