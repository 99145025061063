import React from "react";
import './footerBottom.scss';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const FooterBottom = () => {
    const { t } = useTranslation();

    return (
        <div id={'footer-bottom'} className={'responsive-container__slimmer'}>
            <ul className={'legal-list'}>
                <li><Link to={'/legal-notice'}>{t('LegalNotice')}</Link></li>
                <p className={'separator'}>|</p>
                <li><Link to={'/privacy-policy'}>{t('PrivacyPolicy')}</Link></li>
                {/*<p className={'separator'}>|</p>*/}
                {/*<li><Link to={'/cookie-policy'}>{t('CookiePolicy')}</Link></li>*/}
            </ul>
            <ul>
                <li><p>© {new Date().getFullYear()} - HEXAD SPAIN SL</p></li>
                <p className={'separator'}>|</p>
                <li><p>All rights reserved</p></li>
            </ul>
        </div>
    )
}

export default FooterBottom;
