import React from 'react'
import './ourServicesSection.scss'
import { useTranslation } from 'react-i18next'
import { ServicesGroup } from '../ServicesSection/ServicesGroup'
import serviceGroups from '../ServicesSection/servicesGroup.json'
import useIsMobile from '../../../../hooks/useIsMobile'
const OurServicesSection = () => {
    const { t } = useTranslation()
    const isMobile=useIsMobile()
    const serviceGroupsValues = serviceGroups[t('Language')]

    return <div id={'services'}
             className={isMobile?'responsive-container ':'responsive-container responsive-container__slimmer'}
    >
            <div className={'intro'}
                 style={isMobile?{
                     marginLeft: '2.5em',
                     marginRight: '2.5em'
                 }:{}}
            >
                <h3>{t('OurServices')}</h3>
                <p className={'text-container'}>{t('OurServicesMessageParagraph')}</p>
            </div>
            <ServicesGroup serviceGroups={serviceGroupsValues}/>
        </div>
}

export default OurServicesSection