import React from 'react'
import './KitConsulting.scss'
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary, {
    accordionSummaryClasses,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import HexadButton from '../../Components/HexadButton/HexadButton'

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon  />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]:
        {
            transform: 'rotate(90deg)',
        },
    [`& .${accordionSummaryClasses.content}`]: {
        marginLeft: theme.spacing(1),
    },
    ...theme.applyStyles('dark', {
        backgroundColor: 'rgba(255, 255, 255, .05)',
    }),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomizedAccordion({ kitDigitalQuestions }){

    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <div>

        { kitDigitalQuestions.map((item, index) => (
            <Accordion key={item.id}
                       expanded={expanded === item.id}
                       onChange={handleChange(item.id)}
                       sx={{
                           borderRadius: '8px',
                       }}
            >
                <AccordionSummary aria-controls="panel1d-content"
                                  id="panel1d-header">
                    <h5 style={{
                        fontSize: '16px',
                        textAlign: 'left',
                    }}>
                        {item.question}
                    </h5>
                </AccordionSummary>
                <AccordionDetails>
                    <p style={{
                        textAlign: 'left',
                        fontSize: '16px',
                        scrollBehavior: 'auto',
                    }}>
                        {item.response}
                    </p>

                    {item.segments && (
                        <>
                            <ul style={{
                                fontSize: '16px',
                                marginTop: '16px',
                                marginBottom: '16px',
                                textAlign: 'left',
                            }}>
                                {item.segments && item.segments.title}
                            </ul>
                            {item.segments.segment.map((segmentItem) => (
                                <li style={{
                                    textAlign: 'left',
                                    fontSize: '16px',
                                    scrollBehavior: 'auto',
                                }}
                                    key={segmentItem.id}
                                >
                                    {segmentItem.id} {segmentItem.description}
                                </li>
                            ))}
                        </>
                    )}
                    <div className={'info-buttons'}>
                        <ul>
                            {item.link && (

                                item.link.map((linkItem, index) => (
                                    <li key={index}>

                                        <HexadButton key={linkItem.id}
                                                     text={linkItem.title}
                                                     link={linkItem.url} small
                                                     inverted={true}
                                                     spaced={true}
                                                     color="success"
                                                     variant="text"/>
                                    </li>
                                ))

                            )}
                        </ul>
                    </div>
                </AccordionDetails>
            </Accordion>
            ))}
        </div>

    );
}